<template>
  <div class="hot-issue-details">
    <div class="title">
      {{ HotlessueDetails.title }}111
    </div>
    <div class="issue-details">
      <span>浏览次数：{{ HotlessueDetails.hits == null ? 0 : HotlessueDetails.hits }}次</span>
      <!-- <span>发布时间：{{HotlessueDetails.updated.split("T")[0]}}</span> -->
    </div>
    <div class="news" v-html="HotlessueDetails.content">
    </div>
  </div>
</template>

<script>
import {adornUrl, httpGet, httpPost} from "@/utils/httpRequest";
export default {
  name: "HotIssueDetails",
  data(){
    return{
      HotlessueDetails:"",
    }
  },
  mounted(){
    // console.log(this.$route.query)
    this.getData();
  },
  methods:{
    getData(){
      httpGet("/news-info/detail",
      {
        newsId:this.$route.query.data.id
      },
      (res)=>{
        this.HotlessueDetails = res.data
        // console.log(this.HotlessueDetails)
      },(err)=>{
        // // console.log(err)
        throw err
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.hot-issue-details {
  .title {
    height: 2.125rem;
    font-size: 1.5rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 2.125rem;
    padding-bottom: 0.625rem;
  }

  .issue-details {
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 1.1875rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #EDEFF2;
    margin-bottom: 2rem;

    span:first-child {
      margin-right: 0.75rem;
    }
  }

  .news {
    text-align: left;
    font-size: 1rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E454D;
    line-height: 2rem;
    letter-spacing: 0.125rem;
  }
}
</style>